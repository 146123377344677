<template>
  <v-row>
    <v-app-bar class="pt-4 pl-6 pr-8" flat height="35" app fixed style=" top: 56px; z-index: 5; transition: none !important">
      <v-toolbar-title class="d-block subtitle-1 font-weight-semibold">Task manager</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="pr-0 mr-n2 pb-0">
        <v-row align="center" justify="end" no-gutters>
          <v-tooltip color="bgSearch" open-delay="200" top transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="ml-2" width="35" outlined><v-icon size="19">mdi-table</v-icon></v-btn>
            </template>
            <span class="d-block px-3 py-2 body-2">Visualizar como tabla</span>
          </v-tooltip>
          <v-query-builder class="ml-2" :query-map="filters" model="pos" />
        </v-row>
      </v-toolbar-items>
      <template v-slot:extension>
        <div class="col-12 pa-0 ml-2 mt-n3 d-flex justify-end none">
          <!-- by status filter -->
          <v-tabs v-model="current" class="mt-n3 pt-8 pl-12 pr-0 background" :height="35" style="position: absolute; z-index: 0 !important">
            <v-tab :ripple="false">
              <v-icon left size="18">mdi-format-list-bulleted</v-icon>Todos
            </v-tab>
            <v-tab v-for="(status, i) in statuses" :key="i" :ripple="false">
              <v-icon left size="18">{{status.icon}}</v-icon>{{status.title}}
            </v-tab>
          </v-tabs>
          <!-- end by status filter -->
        </div>
      </template>
    </v-app-bar>

    <v-col class="px-0 mt-25" cols="12">
      <v-row>
        <v-col cols="4">
          <v-card flat>
            <v-card-title>
              <v-icon color="secondary" left size="20">mdi-alert-circle-outline</v-icon>
              <span class="subtitle-1 font-weight-medium">Inicidencias</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-0" style="height: calc(100vh - 285px); overflow-y: auto;">
              <template v-for="(task, i) in tasks.filter(t => t.step === 'issue')">
                <v-hover v-slot="{ hover }" :key="i">
                  <v-sheet @click="dialogTask=true" class="my-4 pa-3 cursor-move" :class="{ 'on-hover': hover }" :elevation="hover ? 8 : 2" rounded="md">
                    <span class="d-block mt-1 mb-2 font-weight-medium">{{task.title}}</span>
                    <span class="d-block text--secondary">{{task.description}}</span>
                    <v-row align="center" class="mt-3">
                      <v-col class="text-truncate">
                        <v-avatar class="mr-3" color="blue-grey lighten-5" size="32"><v-icon>mdi-account</v-icon></v-avatar>
                        <span class="body-2 text--secondary">Sin asignar</span>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-hover>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat>
            <v-card-title>
              <v-icon color="secondary" left size="20">mdi-account-check-outline</v-icon>
              <span class="subtitle-1 font-weight-medium">Asignadas</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-0" style="height: calc(100vh - 285px); overflow-y: auto;">
              <template v-for="(task, i) in tasks.filter(t => t.step === 'assigned')">
                <v-hover v-slot="{ hover }" :key="i">
                  <v-sheet @click="dialogTask=true" class="my-4 pa-3 cursor-move" :class="{ 'on-hover': hover }" :elevation="hover ? 8 : 2" rounded="md">
                    <span class="d-block mt-1 mb-2 font-weight-medium">{{task.title}}</span>
                    <span class="d-block text--secondary">{{task.description}}</span>
                    <v-row align="center" class="mt-3">
                      <v-col class="text-truncate">
                        <v-avatar class="mr-3" size="32"><img src="https://cdn.vuetifyjs.com/images/lists/4.jpg"></v-avatar>
                        <span class="body-2 text--secondary">{{task.user.name}}</span>
                      </v-col>
                      <v-col class="text-right">
                        <v-chip v-if="task.status === 'wip'" color="blue lighten-4" text-color="primary" label small><span class="font-weight-medium">En proceso</span><v-icon right size="18">mdi-progress-clock</v-icon></v-chip>
                        <v-chip v-else-if="task.status === 'review'" color="amber lighten-4" text-color="yellow darken-3" label small><span class="font-weight-medium">En revisión</span><v-icon right size="18">mdi-eye-outline</v-icon></v-chip>
                        <v-chip v-else-if="task.status === 'postponed'" color="deep-orange lighten-4" text-color="deep-orange darken-4" label small><span class="font-weight-medium">Postergado</span><v-icon right size="18">mdi-clock-alert-outline</v-icon></v-chip>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-hover>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat>
            <v-card-title>
              <v-icon color="secondary" left size="20">mdi-check-all</v-icon>
              <span class="subtitle-1 font-weight-medium">Finalizadas</span>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-0" style="height: calc(100vh - 285px); overflow-y: auto;">
              <template v-for="(task, i) in tasks.filter(t => t.step === 'finalized')">
                <v-hover v-slot="{ hover }" :key="i">
                  <v-sheet @click="dialogTask=true" class="my-4 pa-3 cursor-move" :class="{ 'on-hover': hover }" :elevation="hover ? 8 : 2" rounded="md">
                    <span class="d-block mt-1 mb-2 font-weight-medium">{{task.title}}</span>
                    <span class="d-block text--secondary">{{task.description}}</span>
                    <v-row align="center" class="mt-3">
                      <v-col class="text-truncate">
                        <v-avatar class="mr-3" size="32"><img src="https://cdn.vuetifyjs.com/images/lists/4.jpg"></v-avatar>
                        <span class="body-2 text--secondary">{{task.user.name}}</span>
                      </v-col>
                      <v-col class="text-right">
                        <v-chip v-if="task.status === 'done'" color="light-green lighten-4" text-color="light-green darken-4" label small><span class="font-weight-medium">Finalizado</span><v-icon right size="18">mdi-check-all</v-icon></v-chip>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-hover>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <!-- task detail -->
    <v-dialog v-model="dialogTask" width="1024" persistent no-click-animation scrollable>
      <v-card height="620">
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">No coincide importe entre Tiendas / PoS - NCR y Axteroid</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogTask=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0 fontBody--text">
          <v-row no-gutters class="fill-height">
            <v-col>
              <v-tabs v-model="tabs" color="primary" grow height="48" slider-size="3">
                <v-tab><v-icon left size="22">mdi-cube</v-icon>Descripción general</v-tab>
                <v-tab><v-icon left size="22">mdi-message-badge</v-icon>Comentarios y colaboración</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs" class="mr-1">
                <v-tab-item class="pa-4">
                  <v-row>
                    <v-col cols="12">
                      <span class="d-block mt-3 subtitle-2 font-weight-medium"><v-icon class="mt-n1" color="primary" left>mdi-alert-box-outline</v-icon>Resumen del problema</span>
                      <span class="d-block my-6 text--secondary">Vestibulum ullamcorper mauris at ligula. Nulla porta dolor. Vestibulum facilisis, purus nec pulvinar iaculis, ligula mi congue nunc, vitae euismod ligula urna in dolor. Curabitur at lacus ac velit ornare lobortis. Vestibulum ullamcorper mauris at ligula. Nulla porta dolor.</span>
                      <v-alert text icon="mdi-face-agent" shaped prominent color="teal">
                        <span class="d-block mb-3 subtitle-2 font-weight-medium">Desde el equipo de Axteroid</span>
                        <span class="d-block body-1">Vestibulum ullamcorper mauris at ligula. Nulla porta dolor. Vestibulum facilisis, purus nec pulvinar iaculis, ligula mi congue nunc, vitae euismod ligula urna in dolor. Curabitur at lacus ac velit ornare lobortis.</span>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <span class="d-block mt-3 subtitle-2 font-weight-medium"><v-icon class="mt-n1" color="primary" left>mdi-auto-fix</v-icon>Recomendación</span>
                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-avatar color="blue lighten-4" size="32">
                              <span class="subtitle-2 primary--text font-weight-medium">1</span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-1 text--secondary">Verifica la conectividad del punto de venta p4 de la tienda tienda_1</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-avatar color="blue lighten-4" size="32">
                              <span class="subtitle-2 primary--text font-weight-medium">2</span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-1 text--secondary">Reinicia la configuración</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-avatar color="blue lighten-4" size="32">
                              <span class="subtitle-2 primary--text font-weight-medium">3</span>
                            </v-avatar>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="body-1 text--secondary">Válida la conectividad y la centralización de documentos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-alert text icon="mdi-face-agent" shaped prominent color="teal">
                        <span class="d-block mb-3 subtitle-2 font-weight-medium">Desde el equipo de Axteroid</span>
                        <span class="d-block body-1">Vestibulum ullamcorper mauris at ligula. Nulla porta dolor. Vestibulum facilisis, purus nec pulvinar iaculis, ligula mi congue nunc, vitae euismod ligula urna in dolor. Curabitur at lacus ac velit ornare lobortis.</span>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text class="pt-8">
                      <v-container>
                        <v-row class="px-2 pb-5" align="start">
                          <v-avatar class="mr-1" size="30"><img src="https://cdn.vuetifyjs.com/images/lists/4.jpg"></v-avatar>
                          <v-col class="py-0 pr-0">
                            <v-text-field class="publicLink" outlined type="text" hide-details single-line dense placeholder="Escribe un comentario...">
                              <template v-slot:append>
                                <v-btn text :ripple="false" :height="20"><v-icon color="primary">mdi-send</v-icon></v-btn>
                              </template>
                            </v-text-field>
                          </v-col>
                          </v-row>
                        <v-list three-line>
                          <v-list-item>
                            <v-list-item-avatar class="mt-2 ml-n1">
                              <v-avatar class="mr-1" size="30"><img src="https://cdn.vuetifyjs.com/images/lists/3.jpg"></v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content class="ml-n3">
                              <v-list-item-title class="body-1 font-weight-bold secondary--text">Javiera Lara Perez</v-list-item-title>
                              <span class="d-block fontDraw--text caption mt-n1">Hace 10 minutos</span>
                              <v-list-item-subtitle class="secondary--text mt-1 body-2">
                                <span class="primary--text font-weight-medium">@Antonia Palma López</span>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames libero integer potenti at in nec. Urna turpis massa est ultrices faucibus diam et donec vitae.                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-avatar class="mt-2 ml-n1">
                              <v-avatar class="mr-1" size="30"><img src="https://cdn.vuetifyjs.com/images/lists/4.jpg"></v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content class="ml-n3">
                              <v-list-item-title class="body-1 font-weight-bold secondary--text">Antonia Palma López</v-list-item-title>
                              <span class="d-block fontDraw--text caption mt-n1">Hace 3 horas</span>
                              <v-list-item-subtitle class="secondary--text mt-1 body-2">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames libero integer potenti at in nec. Urna turpis massa est ultrices faucibus diam et donec vitae.
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>

            <v-divider vertical />

            <v-col class="ml-0" style="max-width:400px">
              <span class="px-5 d-block pt-4 subtitle-2 text--primary font-weight-medium">Gestión de la incidencia</span>
              <v-list class="mx-5">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-avatar color="blue lighten-4" size="36">
                      <img src="https://cdn.vuetifyjs.com/images/lists/4.jpg">
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-2 text--secondary">Antonia Palma López</v-list-item-title>
                    <v-list-item-subtitle class="body-1 text--secondary"><v-btn text color="primary" height="28">Asignar a otro</v-btn></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="body-1 text--secondary"><v-icon color="primary" left size="18">mdi-eye-circle</v-icon>Estado</v-list-item-title>
                  <v-list-item-subtitle><v-chip color="blue lighten-4" text-color="primary" label small><span class="font-weight-medium">En proceso</span><v-icon right size="18">mdi-progress-clock</v-icon></v-chip></v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="body-1 text--secondary"><v-icon color="primary" left size="18">mdi-clock-time-three</v-icon>Fecha de inicio</v-list-item-title>
                  <v-list-item-subtitle class="body-1 text--secondary">14 Feb. a las 12.30</v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="body-1 text--secondary"><v-icon color="primary" left size="18">mdi-clock-time-three</v-icon>Fecha de término</v-list-item-title>
                  <v-list-item-subtitle class="body-1 text--secondary">15 Feb. a las 16.00</v-list-item-subtitle>
                </v-list-item>
              </v-list>

              <v-divider class="my-4" />

              <span class="px-5 d-block py-2 subtitle-2 text--primary font-weight-medium">Información de contexto</span>
              <v-list class="mx-5">
                <v-list-item>
                  <v-list-item-title class="body-1 text--secondary"><v-icon color="primary" left size="18">mdi-cog</v-icon>Proceso</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-btn class="body-1" color="primary" height="28" target="_blank" text :to="{ name: 'ComplianceProcessDetail', params: { id: 'foo' } }">Tienda 24 - 2023/11/12<v-icon color="primary" right size="18">mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="body-1 text--secondary"><v-icon color="primary" left size="18">mdi-database</v-icon>Fuente</v-list-item-title>
                  <v-list-item-subtitle><img :src="require(`@/assets/brands/brand--ncr.svg`)" alt="brand--ncr" height="18"><span class="d-block-inline position-absolute body-1 text--primary">Tiendas / PoS - <span class="text--secondary">NCR</span></span></v-list-item-subtitle>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title class="body-1 text--secondary"><v-icon color="primary" left size="18">mdi-bug</v-icon>Total incidencias</v-list-item-title>
                  <v-list-item-subtitle class="body-1 text--secondary">841</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end task detail -->
  </v-row>
</template>
<script>
import MiniHeader from '@/components/commons/MiniHeader'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'

export default {
  components: {
    MiniHeader,
    VQueryBuilder
  },
  data: () => ({
    comments: '',
    filters: [],
    tabs: 0,
    current: 0,
    dialogTask: false,
    tasks: [
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: null,
        step: 'issue',
        title: 'No coincide importe con Axteroid',
        user: null
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'wip',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Antonia Palma López',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'wip',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Javiera Lara Pérez',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'postponed',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Cristian Rebolledo',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'review',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Bernardita Mendoza Carrasco',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'review',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Cristian Rebolledo',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'review',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Antonia Palma López',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'review',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Nicolás Barros Aguilera',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'review',
        step: 'assigned',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Cristian Rebolledo',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'done',
        step: 'finalized',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Nicolás Barros Aguilera',
          photo: ''
        }
      },
      {
        description: 'Revisión de falla en la centralización de documentos desde AX-PoS',
        status: 'done',
        step: 'finalized',
        title: 'No coincide importe con Axteroid',
        user: {
          name: 'Javiera Lara Pérez',
          photo: ''
        }
      }
    ],
    statuses: [
      { title: 'Sin asignar', icon: 'mdi-account-question-outline' },
      { title: 'En proceso', icon: 'mdi-progress-clock' },
      { title: 'En revisión', icon: 'mdi-eye-outline' },
      { title: 'Postergado', icon: 'mdi-clock-alert-outline' },
      { title: 'Finalizado', icon: 'mdi-check-all' }
    ]
  }),
  created () {
    if (this.$route.query.ref && this.$route.query.ref === 'task_dialog') {
      this.dialogTask = true
    }

    if (this.$route.query.ref && this.$route.query.activity === 'True') {
      this.tabs = 1
    }
  }
}
</script>
<style>
.textarea-email.v-text-field--enclosed .v-input__append-inner {
  align-self: end;
  padding: 10px 0;
}
</style>